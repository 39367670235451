import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
// ** Assets
import logo from "../../assets/timeIcon.png";

const Sorry = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight < 700 ? "auto" : "100vh",
        backgroundColor: "#FFFDF4",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // Ensures the logo is at the bottom
        paddingTop: window.innerWidth > 800 ? "30px" : "10px",
        paddingBottom: "40px",
      }}
    >
      <Button
        shape="circle"
        style={{
          backgroundColor: "#58595B",
          marginLeft: window.innerWidth > 800 ? "30px" : "10px",
          width: "40px",
        }}
        icon={<LeftOutlined style={{ color: "#FFFDF4" }} />}
        onClick={() => navigate("/start")}
      ></Button>
      <div
        style={{
          marginLeft: window.innerWidth > 800 ? "400px" : "auto",
          marginRight: "auto",
        }}
      >
        <div
          style={{
            marginTop: window.innerHeight < 741 ? "40px" : "100px",
            fontSize: window.innerWidth > 800 ? "36px" : "30px",
            fontWeight: 700,
          }}
        >
          Уучлаарай.
        </div>
        <div
          style={{
            marginTop: "40px",
            fontSize: window.innerWidth > 800 ? "26px" : "20px",
          }}
        >
          Та өнөөдрийн QUIZ-д
        </div>
        <div
          style={{
            fontSize: window.innerWidth > 800 ? "26px" : "20px",
          }}
        >
          оролцсон байна. ​
        </div>
        {/* <div
          style={{
            marginTop: "20px",
            fontSize: window.innerWidth > 800 ? "26px" : "20px",
          }}
        >
          Дараагийн QUIZ
        </div>
        <div
          style={{
            fontSize: window.innerWidth > 800 ? "26px" : "20px",
          }}
        >
          6/19-нд болно шүү.​
        </div> */}
      </div>
      <img
        src={logo}
        alt="loginLogo"
        style={{
          width:
            window.innerWidth > 800
              ? "550px"
              : window.innerHeight < 741
              ? "300px"
              : "350px",
          marginLeft: "auto",
          marginRight: "0",
          marginTop:
            window.innerWidth > 800
              ? "40px"
              : window.innerHeight < 741
              ? "170px"
              : "190px",
          marginBottom: "0px",
        }}
      />
    </div>
  );
};

export default Sorry;
