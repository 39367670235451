import React from "react";
// ** Assets
import logo from "../../assets/startIcon.png";
import mainLogo from "../../assets/mainLogoDark.png";
import finishLogo from "../../assets/finishIcon.png";

const Finish = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#FFFDF4",
        display: "flex",
        flexDirection: "column",
        paddingTop: "30px",
      }}
    >
      <img
        src={mainLogo}
        alt="mainLogo"
        style={{
          width: "250px",
          display: window.innerWidth > 800 ? "flex" : "none",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <img
        src={logo}
        alt="loginLogo"
        style={{
          display: window.innerWidth > 800 ? "flex" : "none",
          width:
            window.innerWidth > 800
              ? "260px"
              : window.innerHeight < 741
              ? "160px"
              : "220px",
          marginLeft: "auto",
          marginRight: "0px",
        }}
      />
      <div
        style={{
          marginTop: window.innerWidth > 800 ? "-550px" : "20px",
        }}
      >
        <img
          src={finishLogo}
          style={{
            width: window.innerWidth > 800 ? "350px" : "250px",
            marginLeft: window.innerWidth > 800 ? "-1300px" : "auto",
            marginTop: window.innerWidth > 800 ? "300px" : "0",
            marginRight: "auto",
          }}
        />
      </div>
      {window.innerWidth < 800 ? (
        <div style={{ fontSize: "38px" }}>Амжилттай</div>
      ) : null}
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: window.innerWidth > 800 ? "-400px" : "10px",
          width: window.innerWidth > 800 ? "440px" : "340px",
          backgroundColor: "#EFEFEF",
          padding: "40px",
          borderRadius: window.innerWidth > 800 ? "70px" : "40px",
          fontSize: window.innerWidth > 800 ? "20px" : "18px",
        }}
      >
        {window.innerWidth > 800 ? (
          <div style={{ fontSize: "36px", marginBottom: "20px" }}>
            Амжилттай
          </div>
        ) : null}
        <div> QUIZ-ийн шилдэг</div>
        <div>оролцогчдын мэдээллийг</div>
        {window.innerWidth > 800 ? (
          <div>My Space болон Unitel All фейсбүүк</div>
        ) : (
          <div>My Space болон Unitel All</div>
        )}
        {window.innerWidth > 800 ? (
          <div>группээс аваарай.</div>
        ) : (
          <div>фейсбүүк группээс аваарай.</div>
        )}
      </div>
    </div>
  );
};

export default Finish;
