import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
// ** Components
import Loading from "../../components/shared-components/Loader";
import Start from "./Start.js";
import Finish from "./Finish.js";
import Question from "./Question.js";
import Error from "../auth-views/Error.js";
import NotFound from "../auth-views/NotFound.js";
import Sorry from "../auth-views/Sorry.js";

export const AppViews = () => {
  // ** Хэрэглэгч нэвтрээгүй бол login хэсэгрүү үсрэнэ.
  function ProtectedRoute({ children }) {
    return children;
  }

  // ** Нэвтэрсэн хэрэглэгчид харагдах бүх page - үүд энд байна.
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Routes>
        <Route
          exact
          path={`/start`}
          element={
            <ProtectedRoute>
              <Start />
            </ProtectedRoute>
          }
        />
         <Route
          index
          exact
          path={`/token=:token`}
          element={
            <ProtectedRoute>
              <Start />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={`/question`}
          element={
            <ProtectedRoute>
              <Question />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={`/finish`}
          element={
            <ProtectedRoute>
              <Finish />
            </ProtectedRoute>
          }
        />
        <Route exact path={`/error`} element={<Error />} />
        <Route exact path={`/notfound`} element={<NotFound />} />
        <Route exact path={`/error1`} element={<Sorry />} />
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppViews);
