import { useParams, useNavigate } from "react-router-dom";
import { Layout } from "antd";
// ** Components
import AppViews from "../../views/app-views";

const { Content, Footer } = Layout;

const AppLayout = () => {

  // ** Role - с хамаарч menu зурагдана.
  return (
    <Layout>
      <AppViews />
    </Layout>
  );
};

export default AppLayout;
