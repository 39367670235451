import React, { useState, useLayoutEffect } from "react";
import { Progress, Button, Typography, Space, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../api";

const { Title } = Typography;

const QuizComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [question, setQuestion] = useState();
  const totalQuestions = 15;

  const getQuestion = async () => {
    setIsLoading((prev) => prev + 1);
    const res = await apiService.callGetUAT(`/quiz/questions`);
    if (res?.data?.id) {
      setQuestion(res?.data);
      setCurrentQuestion(res?.data?.questionNumber);
    } else {
      navigate("/error1");
    }
    setIsLoading((prev) => prev - 1);
  };

  useLayoutEffect(() => {
    getQuestion();
  }, []);

  const handleNext = async () => {
    setIsLoading((prev) => prev + 1);
    setSelectedIndex(null);
    const body = {
      questionId: question?.id,
      selectedAnswer: question.options[selectedIndex],
    };
    const res = await apiService.callPostUAT(`/quiz/answer`, body);
    if (res?.status === 200) {
      getQuestion();
    } else {
      navigate("/finish");
    }
    setIsLoading((prev) => prev - 1);
  };

  const finish = async () => {
    setIsLoading((prev) => prev + 1);
    setSelectedIndex(null);
    const body = {
      questionId: question?.id,
      selectedAnswer: question.options[selectedIndex],
    };
    const res = await apiService.callPostUAT(`/quiz/answer`, body);
    if (res?.status === 200) {
      navigate("/finish");
    } else {
      navigate("/finish");
    }
    setIsLoading((prev) => prev - 1);
  };

  return (
    <Spin spinning={isLoading > 0}>
      <div style={{ backgroundColor: "#FFFDF4", height: "100vh" }}>
        {question ? (
          <div
            style={{
              padding: 20,
              maxWidth: 600,
              margin: "0 auto",
              textAlign: "center",
              backgroundColor: "#FFFDF4",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title
              level={4}
              style={{ marginTop: 20, marginLeft: "0px", marginRight: "auto" }}
            >
              {currentQuestion}/{totalQuestions}
            </Title>
            <Progress
              style={{ marginTop: -15, marginBottom: "20px" }}
              percent={(currentQuestion / totalQuestions) * 100}
              status="active"
              showInfo={false}
              trailColor="#EFEFEF"
              strokeColor="#8BC53F"
            />
            <Title level={4}>
              {currentQuestion}. {question.questionText}
            </Title>
            <Space
              direction="vertical"
              size="large"
              style={{
                width: "300px",
                marginTop: "20px",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              {question?.options
                ? question.options.map((el, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "300px",
                          padding: "10px",
                          border:
                            selectedIndex === index
                              ? "1.8px solid #8BC53F"
                              : "1.8px solid #58595B",
                          borderRadius: "30px",
                          backgroundColor:
                            selectedIndex === index ? "#8BC53F" : "transparent",
                        }}
                        onClick={() => setSelectedIndex(index)}
                      >
                        {el}
                      </div>
                    );
                  })
                : null}
            </Space>
            <Button
              type="primary"
              onClick={() =>
                currentQuestion < totalQuestions ? handleNext() : finish()
              }
              style={{
                backgroundColor: selectedIndex === null ? "#58595B" : "#64BC00",
                border:
                  selectedIndex === null
                    ? "1px solid #58595B"
                    : "1px solid #64BC00",
                borderRadius: "30px",
                width: "140px",
                color: "white",
                padding: "1px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "60px",
                fontSize: "18px",
                height: "40px",
                marginBottom: "40px",
              }}
              disabled={selectedIndex === null}
            >
              {currentQuestion < totalQuestions ? "Дараагийн" : "Дуусгах"}
            </Button>
          </div>
        ) : null}
      </div>
    </Spin>
  );
};

export default QuizComponent;
