import React from "react";
import { Button } from "antd";
import { useNavigate, useParams  } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
// ** Assets
import logo from "../../assets/startIcon.png";
import title from "../../assets/title.png";

const Start = () => {
  const navigate = useNavigate();
  const {token} = useParams();

  if (token) {
    localStorage.setItem("token", token);
  }

  const handleFinish = () => {
    navigate("/question");
  };

  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight < 700 ? "auto" : "100vh",
        backgroundColor: "#FFFDF4",
        display: "flex",
        flexDirection: "column",
        paddingTop: window.innerWidth > 800 ? "30px" : "10px",
        paddingBottom: "40px",
      }}
    >
      {/* <Button
        shape="circle"
        style={{
          backgroundColor: "#58595B",
          marginLeft: window.innerWidth > 800 ? "30px" : "10px",
          width: "40px",
        }}
        icon={<LeftOutlined style={{ color: "#FFFDF4" }} />}
        onClick={() => navigate(-1)}
      ></Button> */}
      <img
        src={logo}
        alt="loginLogo"
        style={{
          width:
            window.innerWidth > 800
              ? "400px"
              : window.innerHeight < 741
              ? "200px"
              : "220px",
          marginLeft: "auto",
          marginRight: "0px",
          marginTop: "-60px",
        }}
      />
      <div
        style={{
          marginTop: window.innerWidth > 800 ? "-400px" : "40px",
        }}
      >
        <img
          src={title}
          style={{ width: window.innerWidth > 800 ? "350px" : "190px" }}
        />
      </div>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "40px",
          width: window.innerWidth > 800 ? "350px" : "300px",
          backgroundColor: "#EFEFEF",
          padding: "50px",
          borderRadius: window.innerWidth > 800 ? "70px" : "40px",
          fontSize: window.innerWidth > 800 ? "20px" : "16px",
        }}
      >
        <div>Хурдан зөв хариулах тусам </div>
        <div>илүү өндөр оноотой шүү.</div>
        <div>Гэхдээ хурдан хийх гээд</div>
        <div>буруу хариулаад байв аа.</div>
      </div>
      <button
        style={{
          backgroundColor: "#64BC00",
          border: "1px solid #64BC00",
          borderRadius: "30px",
          color: "white",
          padding: "8px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "-20px",
          fontSize: "25px",
          width: window.innerWidth > 800 ? "240px" : "120px",
          marginBottom: "40px",
        }}
        onClick={handleFinish}
      >
        Эхлэх
      </button>
    </div>
  );
};

export default Start;
