import { BrowserRouter as Router } from "react-router-dom";
import { message } from "antd";
// ** Context
import { ContextProvider } from "./context/Context";
// ** Layouts
import AuthLayout from "./layout/auth_layout";
import AppLayout from "./layout/app_layout";
// ** css
import "./App.css";
import "./css/light-theme.css";
import "./css/index.css";

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <AppLayout />
          {/* <AuthLayout /> */}
        </Router>
      </ContextProvider>
    </div>
  );
}

export default App;
