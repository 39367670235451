import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
// ** Assets
import logo from "../../assets/errorIcon.png";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight < 700 ? "auto" : "100vh",
        backgroundColor: "#FFFDF4",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // Ensures the logo is at the bottom
        paddingTop: window.innerWidth > 800 ? "30px" : "10px",
        paddingBottom: "0px",
      }}
    >
      <Button
        shape="circle"
        style={{
          backgroundColor: "#58595B",
          marginLeft: window.innerWidth > 800 ? "30px" : "10px",
          width: "40px",
        }}
        icon={<LeftOutlined style={{ color: "#FFFDF4" }} />}
        onClick={() => navigate("/login")}
      ></Button>
      <div
        style={{
          marginLeft: window.innerWidth > 800 ? "500px" : "auto",
          marginRight: "auto",
          marginTop: window.innerWidth > 800 ? "120px" : "0px",
        }}
      >
        <div
          style={{
            marginTop: "10px",
            fontSize: window.innerWidth > 800 ? "32px" : "22px",
            color: "#58595B",
            fontWeight: 500,
          }}
        >
          Дугаар бүртгэлгүй
        </div>
        <div
          style={{
            fontSize: window.innerWidth > 800 ? "32px" : "22px",
            color: "#58595B",
            fontWeight: 500,
          }}
        >
          байна.
        </div>
        <div
          style={{
            fontSize: window.innerWidth > 800 ? "32px" : "22px",
            color: "#58595B",
            fontWeight: 500,
            marginTop: "20px",
          }}
        >
          Та ажилтны дугаараа
        </div>
        <div
          style={{
            fontSize: window.innerWidth > 800 ? "32px" : "22px",
            color: "#58595B",
            fontWeight: 500,
          }}
        >
          оруулна уу!
        </div>
      </div>
      <img
        src={logo}
        alt="loginLogo"
        style={{
          width: window.innerWidth > 800 ? "400px" : "220px",
          marginLeft: "auto",
          marginRight: window.innerWidth > 800 ? "150px" : "0px",
          marginTop: window.innerWidth > 800 ? "-370px" : "50px",
          marginBottom: "0px",
        }}
      />
    </div>
  );
};

export default NotFound;
