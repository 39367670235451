import { createContext, useState, useEffect } from "react";
const Context = createContext();
export function ContextProvider({ children }) {
  const [sideCollapsed, setSideCollapsed] = useState(true);
  const [adminData, setAdminData] = useState(null);
  const [isLoading, setIsLoading] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLoading((prev) => prev + 1);
      const token = localStorage.getItem("token");
      setIsLoading((prev) => prev - 1);
    }
  }, []);

  const value = {
    sideCollapsed,
    setSideCollapsed,
    adminData,
    isLoading,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default Context;
