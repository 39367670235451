import axios from "axios";
const url = process.env.REACT_APP_PUBLIC_URL;
const urlUAT = process.env.REACT_APP_UAT_URL;

export default class Api {
  service = (header) => {
    const headers = {};
    var token = localStorage.getItem("token");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    Object.assign(headers, header);
    this.client = axios.create({
      baseURL: url,
      timeout: 100000,
      headers,
    });

    this.client.interceptors.request.use(
      function (config) {
        // Do something before request is sent

        return config;
      },

      function (error) {
        // Do something with request error

        return Promise.reject(error);
      }
    );
    this.client.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger

        // Do something with response data

        return response;
      },

      function (error) {
        if (error && error.response?.status === 401) {
          // localStorage.removeItem("token");
          // window.location.replace("/index");
        }
        return error;

        // Any status codes that falls outside the range of 2xx cause this function to trigger

        // Do something with response error

        // errorHandler(error.response);

        return Promise.reject(error);
      }
    );

    return this.client;
  };
}

const api = new Api();

function callPost(path, data, header, timeout) {
  return api
    .service(header)
    .post(`${url}${path}`, data, timeout)
    .then((user) => user);
}
function callPostUAT(path, data, header, timeout) {
  return api
    .service(header)
    .post(`${urlUAT}${path}`, data, timeout)
    .then((user) => user);
}
function callGet(path, header, timeout) {
  return api
    .service(header)
    .get(`${url}${path}`, timeout)
    .then((user) => user);
}
function callGetUAT(path, header, timeout) {
  return api
    .service(header)
    .get(`${urlUAT}${path}`, timeout)
    .then((user) => user);
}
function callPut(path, data, header) {
  return api
    .service(header)
    .put(`${url}${path}`, data)
    .then((user) => user);
}
function callDelete(path, data, header) {
  return api
    .service(header)
    .delete(`${url}${path}`, { data })
    .then((user) => user);
}
export const apiService = {
  callPost,
  callGet,
  callPut,
  callDelete,
  callGetUAT,
  callPostUAT,
};
